html {
  background-color: #c0c0c0;
}

.app {
  font-family: 'Times New Roman', 'Arial', sans-serif;
  background-color: #c0c0c0;
  color: black;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  flex-direction: column;
  background-color: #c0c0c0;
  padding: 10px;
}

.navbar {
  display: flex;
  justify-content: space-around;
  padding: 5px;
}

.navbar button {
  font-size: 12px;
  padding: 5px;
  margin: 0;
}

.search-bar {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.search-bar input {
  width: 80%;
  padding: 5px;
}

.main-content {
  margin: 10px;
  padding: 10px;
}

h1 {
  font-size: 32px;
  margin-bottom: 15px;
}

.sub-links {
  display: flex;
  justify-content: start;
  margin-bottom: 20px;
  text-decoration: underline;
}

.sub-links a {
  text-decoration: none;
  color: blue;
  padding-right: 5px;
}

a:visited {
  color: #660099;
}

.track-link.visited {
  color: #660099; /* Netscape-era visited link color */
}

.categories ul {
  list-style: none;
  padding: 0;
}

.categories li {
  font-size: 19px;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.categories li::before {
  content: "•";
  color: black;
  font-weight: bold;
  font-size: 21px;
  line-height: 1;
  margin-right: 10px;
  padding: 0;
}

.categories a {
  text-decoration: underline;
  color: blue;
  font-weight: bold;
}

.count {
  color: black;
  font-style: italic;
  font-weight: bold;
}

.menu {
  border: 3px blue solid;
  vertical-align: middle;
  margin: 0;
  image-rendering: pixelated; /* Modern browsers */
  image-rendering: crisp-edges; /* For some older browsers */
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -webkit-optimize-contrast; /* Safari */
  image-rendering: optimize-contrast; /* CSS3 proposed value */
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

.new {
  vertical-align: middle;
  margin-left: 5px;
  image-rendering: pixelated; /* Modern browsers */
  image-rendering: crisp-edges; /* For some older browsers */
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -webkit-optimize-contrast; /* Safari */
  image-rendering: optimize-contrast; /* CSS3 proposed value */
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
}

.footer {
  margin-top: 20px;
  font-size: 12px;
}

.footer a {
  text-decoration: underline;
  color: blue;
}

.footer p {
  font-style: italic;
}

@keyframes fluctuateBlur {
  0% {
    filter: blur(3px);
  }
  50% {
    filter: blur(6px);
  }
  100% {
    filter: blur(2px);
  }
}

@keyframes randomBlur {
  0% {
    filter: blur(3px);
  }
  20% {
    filter: blur(6px);
  }
  35% {
    filter: blur(4px);
  }
  50% {
    filter: blur(8px);
  }
  65% {
    filter: blur(5px);
  }
  80% {
    filter: blur(7px);
  }
  100% {
    filter: blur(3px);
  }
}

.alien-title {
  display: inline-block;
  cursor: pointer;
  position: relative;
  overflow: visible; /* Allows the blur to extend beyond the container */
}

.alien-text {
  transition: filter 0.3s ease-in-out;
  filter: blur(0); /* Initial state with no blur */
}

.alien-title:hover .alien-text {
  animation: randomBlur 3s infinite ease-in-out;
}



h1 {
  display: flex;
  align-items: center;
  font-size: 32px;
  margin-bottom: 15px;
  line-height: 1; /* Ensures h1 content aligns properly */
}

